
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class Exception extends Vue {
  /** 根据type决定展示哪种异常页面 */
  type = '404';

  config = {
    401: {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
      title: '401',
      desc: '抱歉，你无权访问该页面',
    },
    403: {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
      title: '403',
      desc: '抱歉，你无权访问该页面',
    },
    404: {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
      title: '404',
      desc: '抱歉，你访问的页面不存在或仍在开发中',
    },
    500: {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
      title: '500',
      desc: '抱歉，服务器出错了',
    },
  };

  private handleToHome() {
    this.$router.push('/');
  }

  private mounted() {
    this.type = this.$route.params.type;
  }
}
